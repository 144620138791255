import { configureStore } from '@reduxjs/toolkit'

import authReducer from './state/authSlice'
import navReducer from './state/navSlice'
import counterReducer from './state/counterSlice'

export default configureStore({
    reducer: {
        auth: authReducer,
        counter: counterReducer,
        nav: navReducer
    }
})