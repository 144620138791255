
import store from '../store'

export const isUserAtLeastUserType = (minUserType) => {

    const user = store.getState().auth.user;

    switch (minUserType) {
        case 'Admin':
            return user.UserType === 'Admin';
        case 'Manager':
            return user.UserType === 'Admin' || user.UserType === 'Manager';
        case 'EmissionFactorEditor':
            return user.UserType === 'Admin' || user.UserType === 'EmissionFactorEditor';
        case 'User':
            return user.UserType === 'Admin' || user.UserType === 'Manager' || user.UserType === 'User';
        default:
            throw new Error("Unknown MinUserType: " + minUserType);
    }
    
};

