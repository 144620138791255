import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux'
import { logoutAuth } from '../state/authSlice';
import { isUserAtLeastUserType } from '../state/authHelper';
import { setCurrentNavMenu } from '../state/navSlice';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const NavMenu = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const nav = useSelector(state => state.nav);

    const buildNavItem = (href, title, code, icon, minUserType) => {
        if (isUserAtLeastUserType(minUserType)) {
            const className = (nav.currentNavMenu === code ? 'active' : '') + ' nav-link py-3 border-bottom border-top';
            return (
                <li className="nav-item">
                    <OverlayTrigger placement="right" overlay={<Tooltip>{title}</Tooltip>}>
                        <Link
                            to={href}
                            className={className}
                            title={title}
                            onClick={() => dispatch(setCurrentNavMenu(code))}
                            data-bs-placement="right">
                            <FontAwesomeIcon icon={['far', icon]} size="lg" />
                        </Link>
                    </OverlayTrigger>
                </li>
            );
        } else {
            return null;
        }
    };

    const buildDropdownItem = (href, title, code, icon, minUserType) => {
        if (isUserAtLeastUserType(minUserType)) {
            return (
                <li>
                    <Link to={href} className="dropdown-item" onClick={() => dispatch(setCurrentNavMenu(code))}>
                        <FontAwesomeIcon icon={['far', icon]} size="sm" fixedWidth={true} /> &nbsp; {title}
                    </Link>
                </li>
            );
        } else {
            return null;
        }
    };

    const buildAdminMenu = () => {
        if (isUserAtLeastUserType('Admin')) {
            const className = (nav.currentNavMenu === 'adminmenu' ? 'active ' : '') + 'nav-link py-3 border-bottom border-top cursor-hand dropend';
            return (
                <li className="nav-item">
                    <div className={className}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Administration</Tooltip>}>
                            <div className="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={['far', 'sliders-h-square']} size="lg" />
                            </div>
                        </OverlayTrigger>
                        <ul className="dropdown-menu text-small shadow">
                            <li className="dropdown-divider"/>
                            {buildDropdownItem('/users', 'User Management', 'adminmenu', 'users', 'Admin')}
                            <li className="dropdown-divider"/>
                        </ul>
                    </div>
                </li>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="sidebar">

            <Link to="/" className="d-block pt-3 pb-3 link-primary text-decoration-none text-center w-100 sidebar-header" title="">
                <FontAwesomeIcon icon={['fad', 'book']} size="2x" />
            </Link>

            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                {buildNavItem('/', 'Home', 'home', 'home', 'Manager')}
                {buildNavItem('/emissions', 'Emissions Hub', 'emissions', 'smog', 'EmissionFactorEditor')}
                {buildAdminMenu()}
            </ul>

            <div className="border-top text-center sidebar-lasticon">
                <OverlayTrigger placement="right" overlay={<Tooltip>Profile and Settings</Tooltip>}>
                    <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none cursor-hand" data-bs-toggle="dropdown">
                        <FontAwesomeIcon icon={['far', 'user-circle']} size="lg" />
                    </span>
                </OverlayTrigger>
                <ul className="dropdown-menu text-small shadow ms-3">
                    <li><div className="dropdown-item disabled">{auth.user.UserLogin}</div></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                    <li><Link className="dropdown-item" to='/' onClick={() => dispatch(logoutAuth())}>Sign Out</Link></li>
                </ul>

            </div>

        </div>
    );


};
