import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
import { ensureAuth } from './state/authSlice';
import { isUserAtLeastUserType } from './state/authHelper';

import { library } from '@fortawesome/fontawesome-svg-core'

import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import { LoadingPage } from './pages/LoadingPage';
import { NavMenu } from './components/NavMenu';
import './custom.css'

import '@progress/kendo-theme-bootstrap/dist/all.css';
import 'react-reflex/styles.css'

library.add(far, fad);

const LoginPage = lazy(() => import('./pages/LoginPage'));
const HomePage = lazy(() => import('./pages/HomePage'));

const UserListPage = lazy(() => import('./pages/UserListPage'));
const UserPage = lazy(() => import('./pages/UserPage'));

const EmissionFactorListPage = lazy(() => import('./pages/EmissionFactorListPage'));
const EmissionFactorPage = lazy(() => import('./pages/EmissionFactorPage'));

const WelcomePage = lazy(() => import('./pages/WelcomePage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));

const App = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    

    // todo: this feels like a hack... but we have to guard from calling ensureAuth on every rebind somehow... need to research other patterns
    if (!auth.isFetched) {

        dispatch(ensureAuth());

        return <LoadingPage />

    } else if (auth.user.UserLogin !== undefined) {
            return (
                <>                    
                    <NavMenu />

                    <Suspense fallback={<LoadingPage />}>
                        <Switch>

                            <Route exact path='/'>
                            { auth.user.UserType === "EmissionFactorEditor" &&
                                <EmissionFactorListPage />
                            }
                            { isUserAtLeastUserType('Manager') &&
                                <HomePage />
                            }
                            </Route>  

                            <Route exact path='/users'>
                                <UserListPage />
                            </Route>
                            {isUserAtLeastUserType('EmissionFactorEditor') &&
                                <Route exact path='/emissions'>
                                    <EmissionFactorListPage />
                                </Route>
                            }
 

                            <Route exact path='/emissions/edit/:rowUID'>
                                <EmissionFactorPage />
                            </Route>

                            <Route exact path='/emissions/add'>
                                <EmissionFactorPage />
                            </Route>

                            <Route exact path="/user/edit/:userUID">
                                <UserPage />
                            </Route>

                            <Route exact path="/user/add">
                                <UserPage />
                            </Route>

                            <Route exact path="/profile">
                                <ProfilePage />
                            </Route>

                        </Switch>
                    </Suspense>
                </>
            );

    } else {

        return (
            <Suspense fallback={<LoadingPage />}>
                <Switch>

                    <Route exact path='/welcome/:welcomeCode'>
                        <WelcomePage />
                    </Route>

                    <Route exact path='/welcome'>
                        <WelcomePage />
                    </Route>

                    <Route path="*">
                        <LoginPage />
                    </Route>

                </Switch>
            </Suspense>


        );
    }
};

export default App;
