import { createSlice } from '@reduxjs/toolkit'


// redux slice

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {},
        isFetched: false
    },
    reducers: {

        login: (state, action) => {
            state.user = action.payload;
            state.isFetched = true;
        },

        logout: state => {
            state.user = {};
            state.isFetched = true;
        }

    }
})

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;





// public methods

export const ensureAuth = () => async dispatch => {
    try {

        const response = await fetch('auth/fetch');
        let user = await response.json();
        if (user.ResultCode === 'OK') {

            window.rg4js('setUser', {
                identifier: user.UserLogin,
                isAnonymous: false,
                email: user.EmailAddress ?? (user.UserLogin + '@tahoe.local')
            });

            dispatch(authSlice.actions.login(user));

        } else {
            dispatch(authSlice.actions.logout());
        }

    } catch (e) {
        // auth failed
        dispatch(authSlice.actions.logout());
    }

};

export const logoutAuth = () => async dispatch => {

    const response = await fetch('auth/logout');
    await response.json();
    dispatch(authSlice.actions.logout());

}



