import React from 'react';

export const Spinner = () => {

    return (

        <div className="p-5">
            <div className="index-spinner"></div>
        </div>

    );

}
