import { createSlice } from '@reduxjs/toolkit'

export const navSlice = createSlice({
    name: 'nav',
    initialState: {
        currentNavMenu: ''
    },
    reducers: {
        setCurrentNavMenu: (state, action) => {
            state.currentNavMenu = action.payload;
        }
    }
})

export const { setCurrentNavMenu } = navSlice.actions

export default navSlice.reducer